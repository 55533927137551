import { useEffect, useRef, useState } from 'react';
import { fetchToken } from '../core/firebase';
import { useMutation } from '@tanstack/react-query';
import { storeFcmTokenAPI } from '../api/shared';
import { getSession } from 'core';

async function getNotificationPermissionAndToken() {
    if (!('Notification' in window)) return null;
    if (Notification.permission === 'granted') return await fetchToken();

    if (Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') return await fetchToken();
    }
    return null;
}

const useFcmToken = () => {
    const [notificationPermissionStatus, setNotificationPermissionStatus] = useState<NotificationPermission | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const retryLoadToken = useRef(0);
    const isLoading = useRef(false);
    const session = getSession();
    const isUserLoggedIn = !!(session && session.accessToken);

    const { mutate: sendTokenToBackend } = useMutation(storeFcmTokenAPI, {
        onSuccess: () => console.log('FCM token sent to backend successfully'),
        onError: (error) => console.error('Failed to send FCM token to backend', error),
    });

    const loadToken = async () => {
        if (isLoading.current) return;

        isLoading.current = true;
        const token = await getNotificationPermissionAndToken();

        if (Notification.permission === 'denied') {
            setNotificationPermissionStatus('denied');
            console.info('%cPush Notifications issue - permission denied', 'color: green; background: #c7c7c7; padding: 8px; font-size: 20px');
            isLoading.current = false;
            return;
        }

        if (!token) {
            if (retryLoadToken.current >= 3) {
                console.info('%cPush Notifications issue - unable to load token after 3 retries', 'color: green; background: #c7c7c7; padding: 8px; font-size: 20px');
                isLoading.current = false;
                return;
            }

            retryLoadToken.current += 1;
            console.error('An error occurred while retrieving token. Retrying...');
            isLoading.current = false;
            await loadToken();
            return;
        }

        setNotificationPermissionStatus(Notification.permission);
        setToken(token);
        const data = { fcm_token: token };
        sendTokenToBackend(data);
        isLoading.current = false;
    };

    useEffect(() => {
        if ('Notification' in window && isUserLoggedIn) {
            loadToken();
        }
    }, []);

    return { token, notificationPermissionStatus };
};

export default useFcmToken;
